import Vue from 'vue';
import VueRouter from 'vue-router';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import './validations';
import intlTelInput from 'intl-tel-input';
import axios from 'axios';

const router = new VueRouter({
    mode: 'history',
});

Vue.use(VueRouter);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

window.formInstance = new Vue({
    el: '#form-wrapper',
    router,
    data: {
        fullName: '',
        email: '',
        companyName: '',
        companyUrl: '',
        phone: '',
        intent: '',
        countriesInterest: '',
        IABSector: '',
        message: '',
        terms: false,
        newsletter: false,

        countryCode: '',
        countryCodeLetters: '',
        country: '',

        privacy: {},
        IABCategories: {},
        formLoading: false,
    },
    methods: {
        getPrivacyPolicy() {
            const lang = document.documentElement.lang.substring(0, 2);

            axios.get(`https://studioapps.kwanko.com/api/legal/privacy/contact-form/${lang}`)
                .then(({ data }) => {
                    this.privacy = data;
                });
        },
        getIABCategories() {
            const lang = document.documentElement.lang.substring(0, 2);

            axios.get(`/wp-json/kwanko_api/categories/${lang}`)
                .then(({ data }) => {
                    this.IABCategories = data;
                });
        },
        formSubmit() {
            this.formLoading = true;
            axios.post('/wp-json/kwanko_api/contact-form/', {
                name: this.fullName,
                email: this.email,
                companyName: this.companyName,
                companyUrl: this.companyUrl,
                phone: this.phone,
                intent: this.intent,
                IABSector: this.IABSector,
                countriesInterest: this.countriesInterest,
                message: this.message,
                terms: this.terms,
                newsletter: this.newsletter,

                country: this.country,
                country_code_letters: this.countryCodeLetters,
                country_code: this.countryCode,

                utm_source: document.querySelector('input[name="utm_source"]').value,
                utm_campaign: document.querySelector('input[name="utm_campaign"]').value,
                utm_medium: document.querySelector('input[name="utm_medium"]').value,
                utm_content: document.querySelector('input[name="utm_content"]').value,
                utm_term: document.querySelector('input[name="utm_term"]').value,
                pubid: document.querySelector('input[name="pubid"]').value,
                cookie: document.querySelector('input[name="cookie"]').value,
                page: document.querySelector('input[name="page"]').value,

            }).then((res) => {
                console.log(res);
                this.formLoading = false;
                $('#contact').modal('hide');
                $('#success').modal('show');

                $('body').append('<script>gtag("event", "conversion", {"send_to": "AW-978458619/0jl3CKy-1JcBEPuvyNID"});</script>')
                    .append('<script>gtag("event", "form_success", {"event_category": "lead","event_label": "form_success"});</script>')
                    .append("<script>fbq('track', 'Lead')</script>")
                    .append("<img src='//d.adroll.com/ipixel/GY3PHHSNB5FJDH2Z6DXJ6B/RDZBXDRHUVGEJOEGI3VCD5?name=94cd83d8' width='1' height='1' />")
                    .append('<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=412002&conversionId=896538&fmt=gif" />')
                    .append('<script src="https://platform.twitter.com/oct.js" type="text/javascript"></script><script type="text/javascript">twttr.conversion.trackPid("o1lvz", { tw_sale_amount: 0, tw_order_quantity: 0 });</script>');
                document.querySelector('#kwankoform').reset();
            }).catch((err) => {
                this.formLoading = false;
                this.$refs.formHandler.setErrors(err.response.data);
            });
        },
    },
    mounted() {
        this.getPrivacyPolicy();
        this.getIABCategories();

        const { lang } = document.documentElement;
        let lpCountry = 'GB';
        switch (lang) {
        case 'it-it':
            lpCountry = 'IT';
            break;
        case 'pt-pt':
            lpCountry = 'PT';
            break;
        case 'pt-br':
            lpCountry = 'BR';
            break;
        case 'nl-be':
            lpCountry = 'BE';
            break;
        case 'fr-be':
            lpCountry = 'BE';
            break;
        case 'de-de':
            lpCountry = 'DE';
            break;
        case 'es-es':
            lpCountry = 'ES';
            break;
        case 'fr-fr':
            lpCountry = 'FR';
            break;
        case 'es-mx':
            lpCountry = 'MX';
            break;
        case 'pl-pl':
            lpCountry = 'PL';
            break;
        case 'en-us':
        default:
            lpCountry = 'GB';
            break;
        }
        window.contactIti = intlTelInput(this.$refs.phone_input, {
            formatOnDisplay: false,
            nationalMode: false,
            separateDialCode: true,
            preferredCountries: [lpCountry],
            utilsScript: '/wp-content/themes/kwankotheme/assets/js/vendor/intl-tel-input/build/js/utils.js',
        });
        if (this.$route.hash === '#contact') $('#contact').modal('show');

        // Open modal event
        $('#contact').on('show.bs.modal', () => {
            // Prefill inputs when opening modal
            this.countryCode = `+${contactIti.getSelectedCountryData().dialCode}`;
            this.countryCodeLetters = contactIti.getSelectedCountryData().iso2;
            this.country = contactIti.getSelectedCountryData().name;

            // Handle input placeholder
            // this.$refs.phone_input.attributes.placeholder =
        });

        this.$refs.phone_input.addEventListener('countrychange', () => {
            this.countryCode = `+${contactIti.getSelectedCountryData().dialCode}`;
            this.countryCodeLetters = contactIti.getSelectedCountryData().iso2;
            this.country = contactIti.getSelectedCountryData().name;
        });
    },
});
